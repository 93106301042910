.messageWrapper {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-tighter, 4px);
  align-self: stretch;
  flex-direction: row-reverse;

  &.isLoading {
    align-self: auto;
    justify-content: left;
    margin-left: var(--spacing-small-x, 4px);

    .card {
      align-self: auto;
      padding: var(--spacing-tight, 8px) var(--spacing-medium, 16px);
      flex: none;

      .text {
        line-height: 0;
        max-width: 24px;
      }
    }
  }

  &.error {
    .card {
      background: var(--color-accent-persimmon-subtlest, #FEECEC);
    }
  }

  &.isMe {
    flex-direction: row;

    .card {
      border-radius: var(--corder-radius-small, 8px) var(--corder-radius-small, 8px) var(--corder-radius-none, 0) var(--corder-radius-small, 8px);
      background: var(--color-neutral-background, #FDFDFC);
      box-shadow: 0 4px 8px 3px rgb(38 38 38 / 4%), 0 1px 4px 0 rgb(38 38 38 / 8%);

      .bubbleSpike {
        left: 100%;
        right: auto;
        color: var(--color-neutral-background, #FDFDFC);
      }

      .text {
        white-space: break-spaces;
      }
    }
  }

  .card {
    position: relative;
    display: flex;
    padding: var(--spacing-medium, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-tight, 8px);
    flex: 1 0 0;
    border-radius: var(--corder-radius-small, 8px) var(--corder-radius-small, 8px) var(--corder-radius-small, 8px) var(--corder-radius-none, 0);
    background: var(--color-accent-grape-subtlest, #F0F3FE);
    box-shadow: 0 4px 8px 4px rgb(38 38 38 / 4%), 0 1px 4px 0 rgb(38 38 38 / 24%);
    max-width: calc(100% - 24px - var(--spacing-tight, 8px)); // 24px is the icon width

    .bubbleSpike {
      position: absolute;
      bottom: 0;
      right: 100%;
      line-height: 0;
      color: var(--color-accent-grape-subtlest, #F0F3FE);
    }

    .text {
      align-self: stretch;
      color: var(--color-neutral-content, #262522);
      white-space: normal;

      /* body/small */
      font-family: var(--font-family-body, Figtree);
      font-size: var(--font-size-body-small, 14px);
      font-style: normal;
      font-weight: var(--font-weight-regular, 400);
      line-height: var(--line-height-body-small, 18px); /* 128.571% */
      letter-spacing: var(--letter-spacing-wider, 0.25px);
    }

    .controls {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: var(--spacing-medium, 16px);

      button {
        --button-color: var(--grape_700, #4C5FA9);

        &:disabled {
          --button-color: var(--color-neutral-content-subtler, #6B6860);
        }

        &:hover {
          --button-background-color: var(--white, #fff);
        }
      }

      .leftControls {
        display: flex;
        justify-content: flex-start;
        gap: var(--spacing-small-x, 4px);
      }

      .rightControls {
        display: flex;
        justify-content: flex-end;
        gap: var(--spacing-small-x, 4px);
      }

      .iconButton {
        --button-height: 36px;
        --button-width: 36px;
        --button-padding: 8px;
      }
    }

    .timestamp {
      align-self: stretch;
      color: var(--color-neutral-content-subtler, #6B6860);

      /* body/smallest */
      font-family: var(--font-family-body, Figtree);
      font-size: var(--font-size-body-smallest, 10px);
      font-style: normal;
      font-weight: var(--font-weight-regular, 400);
      line-height: var(--line-height-body-smallest, 12px); /* 120% */
      letter-spacing: var(--letter-spacing-widest, 0.5px);
    }

    .box {
      display: flex;
      padding: 8px var(--spacing-tight, 8px);
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 4px;
      border-radius: var(--corder-radius-small, 8px);
      background: var(--color-neutral-background, #FDFDFC);
      cursor: pointer;

      /* light-theme-elevation/surface/1 */
      box-shadow: 0 1px 2px 0 rgb(38 38 38 / 30%), 0 1px 3px 1px rgb(38 38 38 / 15%);
    }

    .applyConfigIcon {
      width: 16px;
      height: 16px;
    }
    
  }

  .aiIcon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid var(--color-accent-persimmon-subtle, #FFD6CD);
    background: linear-gradient(109deg, #FFA663 0%, #FF7557 51.56%, #E37BD3 100%);
    color: #FFF1EE;
  }

  .references {
    display: flex;
    gap: var(--spacing-small, 8px);
    width: 100%;
    overflow: auto;

    &::after {
      content: '';
      flex: 0 0 8px;
    }

    .referenceLink {
      display: flex;
      width: 120px;
      padding: var(--spacing-small-x, 4px) var(--spacing-tight, 8px);

      .linkContent {
        flex: 1 0 0;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .linkScore {
        &.low {
          color: red;
        }

        &.med {
          color: orange;
        }

        &.high {
          color: green;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  flex: 1 0 0;
  padding: var(--spacing-medium, 16px);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--corder-radius-small, 8px);
  border: 1px solid var(--color-neutral-border, #E1DED5);
  background: var(--color-neutral-surface, #F9F8F6);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%) inset;
  overflow: auto;
  transition: all 0.3s ease;
  margin-bottom: var(--spacing-medium, 16px);
  min-height: 50px;

  &.isSmoothScroll {
    scroll-behavior: smooth;
  }

  .chatHistory {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium, 16px);
    justify-content: flex-end;
    width: 100%;
    flex: 1;
  }

  &.isEmpty {
    min-height: 0;
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border: 0 none;
  }
}

.loadingIcon {
  color: var(--color-accent-grape-base, #6D87F1);
  line-height: 0;
}