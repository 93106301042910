.overlap {
  display: flex;
  align-items: center;

  .description {
    font-size: 14px;
    line-height: 21px;
    color: var(--sand_600);

    b {
      font-weight: 600;
      color: var(--sand_900);
    }
  }

  .progress {
    width: 50px;
    height: 50px;
    flex: 0;
  }
}

.interfaceNotSetup {
  background: var(--canteloupe_0);
  border: 1px solid var(--canteloupe_400);
  padding: 1rem;
  border-radius: .5rem;

  .interfaceNavButton {
    height: 40px;
    border: none;
    cursor: pointer;
    padding: 0 16px;
    outline: none;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    transition: all 100ms ease;
    justify-content: center;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    background-color: var(--grape_500);
    color: var(--sand_0);
    margin-top: .5rem;

    &:hover:not(:disabled) {
      background: var(--grape_700);
    }

    &:active:not(:disabled) {
      background: var(--grape_700);
    }

    &:focus:not(:disabled) {
      box-shadow: 0 0 0 6px rgb(var(--grape_500-raw) / 20%), inset 0 -1px 0 rgb(0 0 0 / 10%);
    }
  }
}

.annotation_limit {
  .input {
    width: 110px;
  }

  .label {
    margin-bottom: 8px;
  }

  .unit {
    width: 60px;
    margin-left: 8px;
    font-size: 14px;
  }

  .summary {
    font-size: 14px;
    color: var(--sand_600);
  }
}
